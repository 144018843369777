<template>
	<div class="p-grid">
		<div class="p-col-12">
			<h1>Invalid session</h1>
			<p>You are not able to handle data withou valid user session. Did you come thru ABRA Flexi UI (using custom buttons)? You can close this window now.</p>
		</div>
	</div>
</template>

<script>

export default {
	data() {
		return {}
	}
}
</script>

<style scoped>

</style>
